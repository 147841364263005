import React, {useEffect} from "react"
import { Base64 } from 'js-base64';
import Tree from './../components/tree';


import Layout from "../components/layout"
import SEO from "../components/seo"
import Cursor from "../components/cursor";
import "./success.css";

const SecondPage = () => {
  const [email, setEmail] = React.useState('');
  const [size, setSize] = React.useState('');
  const [address, setAdress] = React.useState('');
  const [success, setSuccess] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    const loadFromPath = () => {
      if (typeof window !== 'undefined' && window) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let e = url.searchParams.get("email");
        let s = url.searchParams.get("size");
        let a = url.searchParams.get("address");
        // console.log(e, s, a)
        if (e !== null && s !== null && a !== null ) {
          setEmail(Base64.decode(e));
          setAdress(Base64.decode(a));
          setSize(Base64.decode(s));

          sendSlackSuccessMessage(e, a, s);
          setShirts(Base64.decode(s));
        } else {
          setSuccess(false)
        }
      }
    };

    loadFromPath();
  }, []); // empty-array means don't watch for any updates

  const sendSlackSuccessMessage = (e,a,s) => {
    fetch('/.netlify/functions/hello', {
      method: 'post',
      body: JSON.stringify({ email: e, address: a, size: s}),
      headers: new Headers({ "Content-Type": "application/json" })
    }).then( (response) => {
      return response.json();
    }).then( (data) => {
      console.log(data)
    });
  }

  const setShirts = (s) => {
    console.log(JSON.stringify({ size: s}));
    fetch('/.netlify/functions/setShirts', {
      method: 'post',
      body: JSON.stringify({ size: s}),
      headers: new Headers({ "Content-Type": "application/json" })
    }).then( (response) => {
      return response.json();
    }).then( (data) => {
      console.log(data)
    });
  }

  return (
    <Layout>
      <SEO title="Success" />
      <Cursor />
      <div className={ loaded ? 'loader-wrapper loaded' : "loader-wrapper"}>
        <p>Loading...</p>
      </div>
      <div style={{ display: `flex`, justifyContent: `center` }} >
        { success && <h1 style={{ textAlign: `center` }}>{`hi ${email} ${ <br/> } You bought a ${size} shirt`}</h1> }
        { !success && <h1 style={{ textAlign: `center` }}>{`something went wrong please email us`}</h1> }
      </div>
      <div style={{ display: `flex`, justifyContent: `center` }} >
       <Tree
         loaded={ () => setLoaded(true) }
       />
      </div>
    </Layout>
  )
}

export default SecondPage
