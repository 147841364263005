import React, { Component } from "react";
import * as THREE from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 500px;
  height: 500px;
`;

const OrbitControls = require("three-orbit-controls")(THREE);

class Tree extends Component {
  constructor(props) {
    super(props);

    this.animate = this.animate.bind(this);
    this.initializeCamera = this.initializeCamera.bind(this);
  }

  componentDidMount() {
    this.THREE = THREE;
    this.clock = new THREE.Clock();
    this.width = this.mount.getBoundingClientRect().width;
    this.height = this.mount.getBoundingClientRect().height;
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(75, this.width / this.height, 0.1, 4000);
    this.light = new THREE.DirectionalLight( 0xffffff, .9 );
    this.light.position.set( 1, 1, 1 ).normalize();
    this.scene.add( this.light );
    const ambient = new THREE.AmbientLight( 0x404040, 0.5 );
    this.scene.add( ambient );
    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setClearColor( 0x000000, 0 ); // the default

    this.renderer.gammaOutput = true;
    this.renderer.gammaFactor = 2.2;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.renderer.setSize(this.width, this.height);
    this.mount.appendChild(this.renderer.domElement);
    this.initializeCamera();


    const sphere = new this.THREE.SphereBufferGeometry( 0.5, 16, 8 );
    this.light1 = new this.THREE.PointLight( 0x22A0D6, 2, 50 );
    this.light1.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light1 );
    this.light2 = new this.THREE.PointLight( 0x22A0D6, 2, 50 );
    this.light2.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light2 );
    this.light3 = new this.THREE.PointLight( 0x22A0D6, 2, 50 );
    this.light3.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light3 );
    this.light4 = new this.THREE.PointLight( 0x22A0D6, 2, 50 );
    this.light4.add( new this.THREE.Mesh( sphere, new THREE.MeshBasicMaterial( { color: 0x22A0D6 } ) ) );
    this.scene.add( this.light4 );

    const loader = new GLTFLoader();
    this.tree = null;
    this.mixer = null;
    loader.load( 'https://balkans.s3.amazonaws.com/Anemone_1.gltf', ( obj ) => {
      this.tree = obj.scene;
      console.log(obj)
      this.tree.scale.multiplyScalar( 1 );
      this.tree.position.y = 0;
      this.tree.position.z = 0;
      this.tree.rotation.y = -2 * Math.PI;


      this.mixer = new THREE.AnimationMixer( this.tree );
      obj.animations.forEach(( clip ) => {
        console.log(clip)
        this.mixer.clipAction(clip).play();
      });

      this.scene.add( this.tree );

      this.count = 0;
      this.animate();
      this.props.loaded();
    } );
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.frameId);
    this.mount.removeChild(this.renderer.domElement);
  }

  initializeCamera() {
    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 40;
  }

  animate() {
    this.frameId = window.requestAnimationFrame(this.animate);
    if (this.mixer !== null ) {
      this.mixer.update(this.clock.getDelta());
    }
    this.renderer.render(this.scene, this.camera);
    if (this.tree !== null) {
      this.tree.rotation.y += .0005 * Math.PI;
    }

    this.count += .01;
    this.light1.position.x = Math.sin( this.count * 0.7 ) * 30;
    this.light1.position.y = Math.cos( this.count * 0.5 ) * 40;
    this.light1.position.z = Math.cos( this.count * 0.3 ) * 30;
    this.light2.position.x = Math.cos( this.count * 0.3 ) * 30;
    this.light2.position.y = Math.sin( this.count * 0.5 ) * 40;
    this.light2.position.z = Math.sin( this.count * 0.7 ) * 30;
    this.light3.position.x = Math.sin( this.count * 0.7 ) * 30;
    this.light3.position.y = Math.cos( this.count * 0.3 ) * 40;
    this.light3.position.z = Math.sin( this.count * 0.5 ) * 30;
    this.light4.position.x = Math.sin( this.count * 0.3 ) * 30;
    this.light4.position.y = Math.cos( this.count * 0.7 ) * 40;
    this.light4.position.z = Math.sin( this.count * 0.5 ) * 30;
  }

  render() {
    return (
      <Wrapper>
        <div
          id="canvas"
          style={{ width: '500px', height: '500px'}}
          ref={mount => {
            this.mount = mount;
          }}
        />
      </Wrapper>
    );
  }

}
export default Tree;
